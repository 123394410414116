@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  @font-face {
    font-family: "Lato";
    src: url("./fonts/Lato/Lato-Regular.ttf") format("truetype");
    font-style: normal;
  }

  /* h1 {
    @apply text-4xl;
    @apply font-bold;
  }
  h2 {
    @apply text-3xl;
    @apply font-bold;
  }
  h3 {
    @apply text-2xl;
    @apply font-bold;
  }
  h4 {
    @apply text-xl;
    @apply font-semibold;
  }
  h5 {
    @apply text-lg;
    @apply font-semibold;
  }
  h6 {
    @apply text-sm;
    @apply font-semibold;
  } */
}

@layer utilities {
  @variants responsive {
    /* Hide scrollbar for Chrome, Safari and Opera */
    .no-scrollbar::-webkit-scrollbar {
      display: none;
    }

    /* Hide scrollbar for IE, Edge and Firefox */
    .no-scrollbar {
      -ms-overflow-style: none; /* IE and Edge */
      scrollbar-width: none; /* Firefox */
    }
  }
}

.testBackGround {
  background-color: blue;
}

/* React-Calander-Custom-Design */

.react-calendar {
  width: 400px;
  max-width: 100%;
  /* background: white; */
  /* border: 1px solid #a0a096; */
  font-family: Arial, Helvetica, sans-serif;
  line-height: 1.125em;
  padding: 20px;
  border-radius: 10px;
}

.react-calendar--doubleView {
  width: 700px;
}

.react-calendar--doubleView .react-calendar__viewContainer {
  display: flex;
  margin: -0.5em;
}

.react-calendar--doubleView .react-calendar__viewContainer > * {
  width: 50%;
  margin: 0.5em;
}

.react-calendar,
.react-calendar *,
.react-calendar *:before,
.react-calendar *:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.react-calendar button {
  margin: 0;
  border: 0;
  outline: none;
}

.react-calendar button:enabled:hover {
  cursor: pointer;
}

.react-calendar__navigation {
  display: flex;
  height: 44px;
  margin-bottom: 1em;
}

.react-calendar__navigation button {
  min-width: 44px;
  background: none;
  color: white;
}

.react-calendar__navigation button:disabled {
  /* background-color: #f0f0f0; */
  color: red;
}

.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
  /* background-color: #e6e6e6; */
  color: yellow;
}

.react-calendar__navigation__label__labelText {
  color: yellow;
}

.react-calendar__month-view__weekdays {
  text-align: center;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 0.75em;
}

.react-calendar__month-view__weekdays__weekday {
  padding: 0.5em;
  color: rgb(235, 226, 226);
  text-decoration: none;
}

.react-calendar__month-view__weekNumbers .react-calendar__tile {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.75em;
  font-weight: bold;
}
.react-calendar__tile {
  color: white;
}

.react-calendar__month-view__days__day--weekend {
  color: red;
}

.react-calendar__month-view__days__day--neighboringMonth {
  color: #757575;
}

.react-calendar__year-view .react-calendar__tile,
.react-calendar__decade-view .react-calendar__tile,
.react-calendar__century-view .react-calendar__tile {
  padding: 2em 0.5em;
}

.react-calendar__tile {
  max-width: 100%;
  padding: 20px 6.6667px;
  background: none;
  text-align: center;
  line-height: 16px;
  font-size: large;
}

.react-calendar__tile:disabled {
  background-color: #f0f0f0;
}

.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
  background-color: black;
  border-radius: 10px;
}

.react-calendar__tile--now:enabled:hover,
.react-calendar__tile--now:enabled:focus {
  background: #ffffa9;
}

.react-calendar__tile--hasActive {
  background: yellow;
}

.react-calendar__tile--hasActive:enabled:hover,
.react-calendar__tile--hasActive:enabled:focus {
  background: yellow;
}

.react-calendar__tile--active {
  background: yellow;
  border-radius: 10px;
  color: black;
}

.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
  background: yellow;
}

.react-calendar--selectRange .react-calendar__tile--hover {
  background-color: #e6e6e6;
}

.react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list {
  height: 300px !important; /* Set this to your desired height */
  width: 100px !important;
  overflow-y: auto;
}
