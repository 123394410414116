/* .custom-calender-component {
  overflow: visible !important;
  white-space: nowrap !important;
  color: rgb(255, 127, 127);
} */
/* 
.rbc-timeslot-group {
  min-height: 100px;
} */

/* .rbc-time-header {
  display: flex;
} */

/* .rbc-month-row {
  display: inline-table !important;
  flex: 0 0 0 !important;
  min-height: 50px !important;
} */

/* .rbc-event-continues-earlier {
  height: auto !important;
} */

/* .rbc-event {
  height: auto !important;
  text-align: center;
} */

/* .react-calendar__tile {
  color: white;
} */

/* .react-calendar__navigation__label {
  color: black;
} */

/* .react-calendar__navigation button {
  color: black;
} */

/* Rich text editor 👇 👇 */
.App-header {
  background-color: #282c34;
  min-height: 5vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  margin-bottom: 5vh;
  text-align: center;
  padding: 12px;
}

.wrapper-class {
  padding: 2px;
  border: 1px solid #ccc;
}
.editor-class {
  background-color: #525d78;
  padding: 1rem;
  border: 1px solid #ccc;
  color: white;
}
.toolbar-class {
  border: 1px solid #ccc;
}
div.descriptionDiv p {
  min-height: 24px; /* set the height to your desired value */
}
